import { useTranslation } from 'next-i18next';
import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';

import { Tooltip } from '../ToolTip';

export interface FallbackImgProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  alt: string;
  fallbackSrc?: string;
  status?: { hasError: boolean; status: string };
  shouldLoad?: () => void;
}

export const FallbackImg: React.FC<FallbackImgProps> = ({
  fallbackSrc,
  alt,
  src,
  status,
  shouldLoad,
  ...props
}) => {
  const { t } = useTranslation();

  const [imageSrc, setImageSrc] = useState(src);

  if (imageSrc === fallbackSrc) {
    return (
      <div
        className={props.className}
        style={{
          // background: `url(/assets/loading.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          overflow: 'hidden',
          backgroundColor: '#EEE',
        }}
      >
        <div className="absolute flex h-full w-full items-center justify-center p-2 text-sm">
          <div className="flex w-full items-center justify-center space-x-2">
            {status &&
              (!status?.hasError ? (
                <>
                  {/* loading icon */}
                  <svg
                    className="h-5 w-5 animate-spin text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="text-gray-600">
                    {t(status?.status as string)}
                  </span>
                </>
              ) : (
                <Tooltip
                  message={t(status?.status)}
                  className="bg-gray-800 text-white"
                >
                  <div className="flex cursor-pointer items-center space-x-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-5 w-5 text-red-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>

                    <span className="text-gray-600">{t('Error')}</span>
                  </div>
                </Tooltip>
              ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <img
      {...props}
      alt={alt}
      src={imageSrc}
      onError={() => {
        if (imageSrc !== fallbackSrc) setImageSrc(fallbackSrc);
        shouldLoad?.();
      }}
    />
  );
};
