import { useQuery } from 'react-query';

import { useLoggedIn } from '@/context/useLoggedIn';
import { API } from '@/utils/api';

export interface IAllowedResponse {
  hasPylon?: boolean;
  hasNFT?: boolean;
  inWhiteList?: boolean;
  canGetSticker: boolean;
  canGetAnalogozi?: boolean;
}

export const buyPack = async (packId: string) => {
  return API.post(`/packs/buy`, { packId });
};

export const openPack = async (id: string, bundleContent: any) => {
  return API.post(`/packs/open`, { id, bundleContent });
};

export const useAllowedCollection = (id: string) => {
  const { loggedIn } = useLoggedIn();

  return useQuery<IAllowedResponse, Error>(
    `useAllowedCollection_${id}`,
    async () => {
      const response = await API.get(`/packs/buy/allowed`, { params: { id } });
      return response?.data;
    },
    {
      enabled: !!id && loggedIn,
    }
  );
};
