import { useTranslation } from 'next-i18next';
import { FaSpinner } from 'react-icons/fa';

interface ConnectWalletBtnProps {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const ConnectWalletBtn: React.FC<ConnectWalletBtnProps> = ({
  onClick,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <button
      className="mr-2 inline-flex items-center rounded bg-yellow-500 py-3 px-5 font-hiraginoBold text-[10px] hover:bg-opacity-[0.7]"
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <FaSpinner className="animate-spin" />
      ) : (
        <span>{t('CONNECT WALLET')}</span>
      )}
    </button>
  );
};
