import { twMerge } from 'tailwind-merge';

import { CustomIcon, CustomIconType } from '../../atoms';

interface SocialLinkType {
  type: CustomIconType;
  url: string;
  size?: string;
}

interface SocialIconsProps {
  links: SocialLinkType[];
  className?: string;
}

export const SocialIconRow: React.FC<SocialIconsProps> = ({
  links,
  className,
}) => {
  return (
    <>
      <section
        className={twMerge(
          'mt-5 flex flex-row gap-4 lg:gap-6 items-center',
          className
        )}
      >
        {links.map((item) =>
          item.url ? (
            <a
              target="_blank"
              key={item.type}
              href={item.url}
              rel="noopener noreferrer"
            >
              <CustomIcon className={item.size} type={item.type} />
            </a>
          ) : (
            ''
          )
        )}
      </section>
    </>
  );
};
