import React from 'react';
import { twMerge } from 'tailwind-merge';

import { BoatIcon } from './BoatIcon';
import { DiscordIcon } from './DiscordIcon';
import { IconProps } from './IconProps';
import { InstagramIcon } from './InstagramIcon';
import { TwitterIcon } from './TwitterIcon';
import { WebIcon } from './WebIcon';

export type CustomIconType =
  | 'discord'
  | 'opensea'
  | 'twitter'
  | 'web'
  | 'instagram'
  | 'twitter';
interface CustomIconProps {
  type: CustomIconType;
  width?: string | number;
  height?: string | number;
  className?: string;
}

const icon: Record<CustomIconType, React.FC<IconProps>> = {
  discord: DiscordIcon,
  opensea: BoatIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  web: WebIcon,
};

export const CustomIcon: React.FC<CustomIconProps> = ({
  type,
  className = '',
}) => {
  if (type in icon) {
    const Component = icon[type];
    return <Component className={twMerge('w-5 h-5', className)} />;
  }
  return <></>;
};
