import { ethers } from 'ethers';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';

import { useLoggedIn } from '@/context/useLoggedIn';
import { useWeb3Accounts } from '@/context/Web3Accounts';
import { useMetaMask } from '@/hooks';
import {
  buyPack,
  openPack,
  useAllowedCollection,
} from '@/hooks/packs/usePacks';
import { web3Util } from '@/utils';

import { CustomModal } from '../../atoms';
import { HowToGetPacks } from '../HowToGetPacks';
import { SocialIconRow } from '../SocialIconRow';

// No types on hazamalib so it will complain if normal import
const hazlib = require('hazama-lib');

interface StickersPackModalProps {
  visible: boolean;
  setVisible: Function;
  data: any;
  loading: boolean;
  type: string;
}

const allowedWallets: string[] = [
  // '0x0de5f36df3771a681899d8d58de39e6834d90ef6',
  // '0xdcbdf76753f0e71e722b119bc202981a24a0bc91',
];

export const StickersPackModal: React.FC<StickersPackModalProps> = ({
  visible,
  setVisible,
  data,
  type,
  loading,
}) => {
  const { t } = useTranslation();

  const { accounts } = useWeb3Accounts();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { updateStickers } = useMetaMask();
  const { loggedIn } = useLoggedIn();
  const [connectingMetamask, setConnectingMetamask] = useState(false);
  const { connectMetamask } = useMetaMask();
  const { data: allowedCollectionData, isLoading: loadingCanGetSticker } =
    useAllowedCollection(data?.id);

  const { canGetSticker } = allowedCollectionData ?? {};

  const allowedUser =
    allowedWallets.length === 0 ||
    (accounts &&
      accounts[0] &&
      allowedWallets.includes(accounts[0].toLowerCase()));

  const connectToMetamask = async () => {
    if (!loggedIn) {
      setConnectingMetamask(true);
      try {
        await connectMetamask.mutateAsync();
        window.location.reload();
      } catch {
        setConnectingMetamask(false);
      }
    } else {
      window.location.reload();
    }
  };

  const buyPackMutation = useMutation(async () => {
    const { data: trx } = await buyPack(data.id);
    const { ethereum } = window;
    if (ethereum && trx) {
      await web3Util.changeNetwork();
      const hash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [{ ...trx }],
      });
      await web3Util.waitForTransaction(hash);
      queryClient.invalidateQueries('mycollections');
      queryClient.invalidateQueries('mycollections-stickers');
      router.push('/studio/stickerpacks/mycollection');
    }
  }, {});

  const openPackMutation = useMutation(async () => {
    const { ethereum } = window;

    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: process.env.NEXT_PUBLIC_PACK_NETWORK_HEX,
          chainName: process.env.NEXT_PUBLIC_PACK_NETWORK_DISPLAY_NAME,
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: [process.env.NEXT_PUBLIC_PACK_NETWORK_RPC_URL],
          blockExplorerUrls: [
            process.env.NEXT_PUBLIC_PACK_NETWORK_EXPLORER_URL,
          ],
        },
      ],
    });
    const providers = await new ethers.providers.Web3Provider(ethereum);
    const signer = providers.getSigner();
    const relayerUrl = process.env.NEXT_PUBLIC_RELAYER_URL;

    const bundleContent = await hazlib.getWrappedContents({
      network: process.env.NEXT_PUBLIC_PACK_NETWORK,
      contractAddress: data.contract_address,
      tokenId: data.token_id,
    });

    await hazlib.unwrap({
      signer,
      relayerUrl,
      network: process.env.NEXT_PUBLIC_PACK_NETWORK,
      contractAddress: data.contract_address,
      tokenId: data.token_id,
      recipientAddress: accounts[0],
    });

    await openPack(data.collection_id, bundleContent);
    await updateStickers.mutateAsync(true);
    queryClient.invalidateQueries('mycollections');
    queryClient.invalidateQueries('mycollections-stickers');
    router.push('/studio/stickerpacks/mycollection');
  });

  const hasPackCondition =
    data?.condition_wl || data?.condition_pylon || data?.condition_contracts;

  if (loading)
    return (
      <>
        <CustomModal
          visible={visible}
          bgColor="white"
          onClose={() => setVisible()}
        >
          <div className="h-[50vh] min-w-[90vw]  bg-white p-5 md:min-w-[80vw] md:px-16 lg:min-w-[40vw]">
            <div className="flex h-full w-full items-center justify-center">
              <FaSpinner className="animate-spin" />
            </div>
          </div>
        </CustomModal>
      </>
    );

  return (
    <CustomModal visible={visible} bgColor="white" onClose={() => setVisible()}>
      <div className="max-h-[80vh] overflow-scroll">
        <div className="flex flex-col overflow-auto bg-white p-5 md:px-16 md:pb-10">
          <img
            src={data?.cover_image}
            alt="metapylon"
            className="h-auto w-full"
          />
          <div className="mt-10 flex gap-4 md:gap-10">
            <div className="w-[40%] max-w-[267px] flex-none">
              <img
                src={data?.sample_image}
                alt="metapylon"
                className="h-auto w-full rounded-sm hover:opacity-[0.9]"
              />
            </div>
            <div className="flex flex-col  text-left md:w-2/3">
              <div className="text-xs md:text-sm">{data?.label}</div>
              <div className="font-goboldRegular text-[20px] md:text-3xl">
                {data?.name}
              </div>
              <div className="py-2 text-sm font-light text-[#797979]">
                {data?.description}
              </div>

              {type === 'open' || type === 'opened' ? (
                <div>
                  {!!data && (
                    <div className="mt-[10px] font-goboldRegular text-[20px] md:text-3xl">
                      {data.purchased_count} / {data?.total_packs}
                    </div>
                  )}
                  <div className="text-sm font-light text-[#797979]">
                    <div className="py-1 ">
                      {t('Total pack volume')}: {data?.total_packs}
                    </div>
                    <div className="py-1">
                      {t('Sticker collection')}: {data?.total_stickers}
                    </div>
                    <div className="py-1">
                      {t('Number of contents')}: {data?.sticker_per_pack}
                    </div>
                    <div className="py-1">
                      {t('Purchase date')}:{' '}
                      {new Date(data?.purchased_at).toLocaleDateString(
                        'en-US',
                        {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <SocialIconRow
                links={[
                  {
                    type: 'web',
                    url: data?.website_url,
                  },
                  {
                    type: 'opensea',
                    url: data?.opensea_url,
                  },
                  {
                    type: 'discord',
                    url: data?.discord_url,
                    size: 'h-6 w-6',
                  },
                  {
                    type: 'instagram',
                    url: data?.instagram_url,
                  },
                  {
                    type: 'twitter',
                    url: data?.twitter_url,
                    size: 'h-6 w-6',
                  },
                ]}
              />
              <div className="mt-[20px] hidden md:inline-block md:w-[240px]">
                {type === 'buy' && data.purchaseCount >= data.sale_packs && (
                  <div className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-gray-300 py-2 text-center font-goboldRegular text-[22px] md:w-[170px]">
                    {t('SOLD')}
                  </div>
                )}

                {type === 'buy' &&
                (data?.status === 'NOW ON SALE' ||
                  data?.status === 'LIMITED') &&
                data.purchaseCount < data.sale_packs ? (
                  <div
                    className={`uppercase font-semibold font-sans text-center text-sm`}
                  >
                    {data?.amount} ETH
                  </div>
                ) : null}

                {type === 'buy' &&
                (data.status === 'NOW ON SALE' || data.status === 'LIMITED') &&
                data.purchaseCount < data.sale_packs ? (
                  <>
                    {loggedIn ? (
                      <>
                        <button
                          disabled={
                            loadingCanGetSticker ||
                            (data?.name === 'ANALOGOZI HOLDERS PAC' &&
                              allowedCollectionData &&
                              !allowedCollectionData.canGetAnalogozi) ||
                            !canGetSticker ||
                            !allowedUser ||
                            buyPackMutation.isLoading
                          }
                          onClick={async () => {
                            buyPackMutation.mutateAsync();
                          }}
                          className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center font-goboldRegular  text-[22px] uppercase hover:bg-opacity-[0.7] md:w-[170px]"
                        >
                          {buyPackMutation.isLoading ? (
                            <FaSpinner className="animate-spin" />
                          ) : (
                            <>{allowedUser ? t('GET NOW') : 'Coming soon'}</>
                          )}
                        </button>
                        {!loadingCanGetSticker &&
                          data?.name === 'ANALOGOZI HOLDERS PAC' &&
                          allowedCollectionData &&
                          !allowedCollectionData.canGetAnalogozi && (
                            <div className="py-2 text-sm font-light text-amber-600">
                              {t(
                                'To GET this pack, your wallet address must be on our allowed list.'
                              )}
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        {connectingMetamask ? (
                          <button className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]">
                            <FaSpinner className="animate-spin" />
                          </button>
                        ) : (
                          <button
                            onClick={connectToMetamask}
                            className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]"
                          >
                            <Image
                              src="/assets/MetaMask_Fox.svg"
                              width="18px"
                              height="18px"
                              alt="metamask"
                            />
                            <span className="ml-3 text-base">
                              {t('CONNECT MetaMask')}
                            </span>
                          </button>
                        )}
                      </>
                    )}
                  </>
                ) : null}

                {type === 'open' ? (
                  <>
                    {loggedIn ? (
                      <button
                        disabled={openPackMutation.isLoading || !allowedUser}
                        className="mt-1 inline-flex h-[60px] w-full  min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]"
                        onClick={async () => {
                          await openPackMutation.mutateAsync();
                        }}
                      >
                        {openPackMutation.isLoading ? (
                          <FaSpinner className="animate-spin" />
                        ) : (
                          <>{allowedUser ? t('open pack') : 'Coming soon'}</>
                        )}
                      </button>
                    ) : (
                      <>
                        {connectingMetamask ? (
                          <button className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]">
                            <FaSpinner className="animate-spin" />
                          </button>
                        ) : (
                          <button
                            onClick={connectToMetamask}
                            className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]"
                          >
                            <Image
                              src="/assets/MetaMask_Fox.svg"
                              width="18px"
                              height="18px"
                              alt="metamask"
                            />
                            <span className="ml-3 text-base">
                              {t('CONNECT MetaMask')}
                            </span>
                          </button>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className=" mt-[20px] block text-center md:hidden md:w-[170px]">
            {type === 'buy' && data.purchaseCount >= data.sale_packs && (
              <div className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-gray-300 py-2 text-center font-goboldRegular text-[22px] md:w-[170px]">
                {t('SOLD')}
              </div>
            )}

            {type === 'buy' &&
            (data?.status === 'NOW ON SALE' || data?.status === 'LIMITED') &&
            data.purchaseCount < data.sale_packs ? (
              <div
                className={`uppercase font-semibold font-sans text-center text-sm`}
              >
                {data?.amount} ETH
              </div>
            ) : null}

            {type === 'buy' &&
            (data.status === 'NOW ON SALE' || data.status === 'LIMITED') &&
            data.purchaseCount < data.sale_packs ? (
              <>
                {loggedIn ? (
                  <>
                    <button
                      disabled={
                        loadingCanGetSticker ||
                        (data?.name === 'ANALOGOZI HOLDERS PAC' &&
                          allowedCollectionData &&
                          !allowedCollectionData.canGetAnalogozi) ||
                        !canGetSticker ||
                        !allowedUser ||
                        buyPackMutation.isLoading
                      }
                      onClick={async () => {
                        buyPackMutation.mutateAsync();
                      }}
                      className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center font-goboldRegular  text-[22px] uppercase hover:bg-opacity-[0.7] md:w-[170px]"
                    >
                      {buyPackMutation.isLoading ? (
                        <FaSpinner className="animate-spin" />
                      ) : (
                        <>{allowedUser ? t('GET NOW') : 'Coming soon'}</>
                      )}
                    </button>
                    {!loadingCanGetSticker &&
                      data?.name === 'ANALOGOZI HOLDERS PAC' &&
                      allowedCollectionData &&
                      !allowedCollectionData.canGetAnalogozi && (
                        <div className="py-2 text-sm font-light text-amber-600">
                          {t(
                            'To GET this pack, your wallet address must be on our allowed list.'
                          )}
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    {connectingMetamask ? (
                      <button className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]">
                        <FaSpinner className="animate-spin" />
                      </button>
                    ) : (
                      <button
                        onClick={connectToMetamask}
                        className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]"
                      >
                        <Image
                          src="/assets/MetaMask_Fox.svg"
                          width="18px"
                          height="18px"
                          alt="metamask"
                        />
                        <span className="ml-3 text-base">
                          {t('CONNECT MetaMask')}
                        </span>
                      </button>
                    )}
                  </>
                )}
              </>
            ) : null}

            {type === 'open' ? (
              <>
                {loggedIn ? (
                  <button
                    disabled={openPackMutation.isLoading || !allowedUser}
                    className="mt-1 inline-flex h-[60px] w-full min-w-[240px]  items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center font-goboldRegular  text-[22px] uppercase hover:bg-opacity-[0.7] md:w-[170px]"
                    onClick={async () => {
                      await openPackMutation.mutateAsync();
                    }}
                  >
                    {openPackMutation.isLoading ? (
                      <FaSpinner className="animate-spin" />
                    ) : (
                      <>{allowedUser ? t('open pack') : 'Coming soon'}</>
                    )}
                  </button>
                ) : (
                  <>
                    {connectingMetamask ? (
                      <button className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]">
                        <FaSpinner className="animate-spin" />
                      </button>
                    ) : (
                      <button
                        onClick={connectToMetamask}
                        className="mt-1 inline-flex h-[60px] w-full min-w-[240px] items-center justify-center rounded border-b-2 border-r-2 bg-yellow-500 py-2 text-center  font-goboldRegular text-[22px] hover:bg-opacity-[0.7] md:w-[170px]"
                      >
                        <Image
                          src="/assets/MetaMask_Fox.svg"
                          width="18px"
                          height="18px"
                          alt="metamask"
                        />
                        <span className="ml-3 text-base">
                          {t('CONNECT MetaMask')}
                        </span>
                      </button>
                    )}
                  </>
                )}
              </>
            ) : null}

            {type === 'opened' ? (
              <div className="mt-1 inline-flex h-[60px] w-full max-w-[151px] items-center justify-center rounded border-b-2 border-r-2 bg-[#EEEDEF] py-2 text-center  font-goboldRegular text-sm md:w-[170px]">
                {t('OPENED')}
              </div>
            ) : null}
          </div>
          {allowedCollectionData && data && hasPackCondition && (
            <HowToGetPacks pack={data} data={allowedCollectionData} />
          )}
        </div>
      </div>
    </CustomModal>
  );
};
