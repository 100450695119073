import { IconProps } from './IconProps';

export const InstagramIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={className}
    >
      <path d="M10.121 5.879A3 3 0 1 1 5.88 10.12 3 3 0 0 1 10.12 5.88" />
      <path d="M11.5 0h-7A4.505 4.505 0 0 0 0 4.5v7C0 13.981 2.019 16 4.5 16h7c2.481 0 4.5-2.019 4.5-4.5v-7C16 2.019 13.981 0 11.5 0ZM8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4Zm4.5-8a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z" />
    </svg>
  );
};
