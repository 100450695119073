import { Shimmer } from 'react-shimmer';

interface IPylonCardShimmerProps {
  width?: number;
  height?: number;
}

export const PylonCardShimmer: React.FC<IPylonCardShimmerProps> = ({
  width,
  height,
}) => (
  <section className="flex w-full max-w-[330px] flex-col px-5 shadow-sm hover:shadow-xl md:px-0 lg:w-[32%]">
    <Shimmer className="!w-full" width={width ?? 400} height={height ?? 310} />
  </section>
);
