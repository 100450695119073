import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { Pylon } from '@/types/pylon';
import { API } from '@/utils/api';

export const useMyPylons = () => {
  return useQuery<Pylon[], Error>('mypylons', async () => {
    const response = await API.get<Pylon[]>(`/mypylon`);
    return response?.data;
  });
};

export const usePylon = (
  pylonId: String,
  options?: Omit<
    UseQueryOptions<Pylon, Error, Pylon, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<Pylon, Error>(
    ['pylon', pylonId],
    async () => {
      const response = await API.get<Pylon>(`/pylons/${pylonId}`);

      return response?.data;
    },
    options
  );
};
