import { useTranslation } from 'next-i18next';
import { FaDiscord } from 'react-icons/fa';

interface Props {
  href: string;
}

export const AskDiscordBtn = ({ href }: Props) => {
  const { t } = useTranslation();

  return (
    <a
      href={href}
      target="_blank"
      className="flex items-center gap-2 rounded-3xl bg-[#5865F2]  px-4 py-2 text-xs text-white hover:opacity-80"
      rel="noreferrer"
    >
      <FaDiscord color="white" size="1.5rem" />
      {t('Ask on Discord')}
    </a>
  );
};
