import { CustomModal } from '../../atoms';

interface StickersPackModalProps {
  visible: boolean;
  setVisible: Function;
  data: any;
}

export const StickerCollectionModal: React.FC<StickersPackModalProps> = ({
  visible,
  setVisible,
  data,
}) => {
  if (!data) {
    return <></>;
  }
  return (
    <CustomModal
      visible={visible}
      bgColor="white"
      onClose={() => {
        setVisible(false);
      }}
    >
      <div className="flex w-[85vw] max-w-xl flex-col overflow-auto overscroll-contain bg-white px-5 pt-5 pb-10 md:px-32">
        <div className="self-center">
          <img
            src={data.image_url}
            alt={data.name}
            width="280"
            height="280"
            className="rounded-sm"
          />
        </div>

        <div className="flex flex-col pt-8 text-center">
          <div className="uppercase">{data.label}</div>
          <div className="font-goboldRegular text-2xl uppercase">
            {data.name}
          </div>
          <div className="py-2 text-sm font-light leading-[1]">
            {data.description}
          </div>

          <div className="pt-4 font-goboldRegular text-2xl">
            #{data.token_id}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
