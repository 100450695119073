import React, { ReactNode, useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';

interface CustomDropdownProps {
  options: Array<string>;
  dropdownStyle?: string;
  ulStyle?: string;
  liStyle?: string;
  image?: string;
  icon?: ReactNode;
  name?: string;
  wallet?: string;
  buttonStyle?: string;
  onSelect: (opt: string) => void;
}

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  dropdownStyle,
  ulStyle,
  liStyle,
  image,
  name,
  wallet,
  buttonStyle,
  onSelect,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const closeModal = () => setOpen(false);
    document.addEventListener('click', closeModal);
    return () => document.removeEventListener('click', closeModal);
  }, []);

  return (
    <>
      <div className="relative">
        <button
          className={twMerge(
            'flex items-center justify-between rounded-lg border border-gray-400 p-1 h-7',
            buttonStyle
          )}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          <div className="flex items-center">
            {image && (
              <img
                className="mr-2 h-5 w-5 rounded-full object-cover"
                src={image}
                alt="avatar"
              />
            )}
            {icon && icon}
            {name && <span className="text-xs font-bold">{name}</span>}
          </div>
          {wallet && (
            <span className="ml-auto text-[10px] font-bold text-gray-600 ">
              {wallet}
            </span>
          )}
          {open ? (
            <FiChevronUp
              className={twMerge(
                'pl-1 text-sm font-bold text-gray-600',
                wallet ? 'ml-0' : 'ml-2'
              )}
            />
          ) : (
            <FiChevronDown
              className={twMerge(
                'pl-1 text-sm font-bold text-gray-600',
                wallet ? 'ml-0' : 'ml-2'
              )}
            />
          )}
        </button>

        <div
          className={twMerge(
            'w-full absolute rounded-md shadow-lg z-10 transition duration-200 ease-in-out',
            dropdownStyle,
            open ? 'visible opacity-1' : 'invisible opacity-0'
          )}
        >
          <ul
            className={twMerge(
              'bg-white py-1 px-0 text-sm text-black-500 shadow-lg',
              ulStyle
            )}
            aria-labelledby="dropdownDefault"
          >
            {options.map((option) => (
              <li
                className={twMerge(
                  'px-3 py-2 ease-in-out cursor-pointer hover:bg-gray-200 bg-white flex items-center space-x-2 whitespace-nowrap',
                  liStyle
                )}
                onClick={() => onSelect(option)}
                key={option}
              >
                <span>{option}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

CustomDropdown.defaultProps = {
  options: [],
};
