import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const Tooltip = ({
  message,
  children,
  className,
}: {
  message: string;
  children: ReactNode;
  className?: string;
  wrapWidth?: boolean;
}) => {
  return (
    <div className="group relative flex w-full flex-col items-center">
      {children}
      <div
        className={
          'absolute bottom-0 mb-6 hidden max-w-full flex-col items-center group-hover:flex'
        }
      >
        <span
          className={twMerge(
            'relative z-10 rounded-md w-fit bg-white p-2 text-xs leading-4 shadow',
            className
          )}
        >
          {message}
        </span>
        <div
          className={twMerge('-mt-2 h-3 w-3 rotate-45 bg-white', className)}
        ></div>
      </div>
    </div>
  );
};
