import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import EYE from '@/assets/eye.svg';
import Heart from '@/assets/heart.svg';
import { incrementLikes, incrementViews, useIndividualGallery } from '@/hooks';
import { formatNumber } from '@/utils/formatNumber';

import { CustomLoader, CustomModal, FallbackImg } from '../../atoms';

interface GalleryModalProps {
  visible: boolean;
  setVisible: Function;
  selectedItemId: string;
  animationUrl: string;
}

export const GalleryModal: React.FC<GalleryModalProps> = ({
  visible,
  setVisible,
  selectedItemId,
  animationUrl,
}) => {
  const { t } = useTranslation();

  const flag = useRef<boolean>(true);
  const queryClient = useQueryClient();
  const { data: item, isLoading } = useIndividualGallery(selectedItemId);
  const increaseLikes = useMutation(() => incrementLikes(selectedItemId), {
    onSuccess: () => {
      queryClient.invalidateQueries('pylons');
      queryClient.invalidateQueries('gallery');
    },
  });

  const { mutate: increaseViews } = useMutation(
    () => incrementViews(selectedItemId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pylons');
      },
    }
  );

  useEffect(() => {
    if (flag.current) {
      increaseViews();
    }
    return () => {
      flag.current = false;
    };
  }, [increaseViews]);

  return (
    <CustomModal
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
    >
      <div className="relative flex h-3/4 max-h-[80vh] w-[90vw] max-w-5xl flex-col overflow-auto overscroll-contain p-10 text-white md:h-[560px]">
        {isLoading && (
          <div className="flex h-full flex-col items-center justify-center">
            <CustomLoader />
          </div>
        )}
        {!isLoading && (
          <>
            <span className="mb-2 text-3xl">{`#${
              item?.token_id ? item.token_id : 0
            }`}</span>
            <div className="box-border flex h-[calc(100%-1rem)] flex-col md:flex-row">
              <div className="h-full w-full overflow-hidden  md:w-[400px]">
                <FallbackImg
                  src={animationUrl}
                  className="h-full w-full object-cover"
                  alt="pylon"
                  fallbackSrc={'/assets/landing/conebag.png'}
                />
              </div>
              <div className="flex h-full w-full flex-1 flex-col md:ml-12 md:w-1/2">
                <div className="flex flex-col justify-between border-b-2 border-gray-500 py-4 md:flex-row">
                  <div className="flex items-center">
                    <span className="mr-2 text-sm font-extralight text-gray-500">
                      {t('Owned by')}
                    </span>
                    <span className="font-medium">
                      {item?.User?.username ? item.User?.username : 'NA'}
                    </span>
                  </div>
                  <div className="mt-1 flex items-center text-xl font-extralight md:mt-0">
                    <span className="mr-2 flex items-center">
                      <>
                        <EYE className="mr-1" />
                        {formatNumber(Number(item?.views), 1) || null}
                      </>
                    </span>
                    <span className="flex items-center">
                      <>
                        <Heart
                          className="mr-1 cursor-pointer"
                          onClick={() => increaseLikes.mutate()}
                        />
                        {formatNumber(Number(item?.likes), 1)}
                      </>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col justify-between border-b-2 border-gray-500 py-4 md:flex-row">
                  <div className="flex items-center">
                    <span className="mr-2 text-sm font-extralight text-gray-500">
                      {t('NFT Collaboration')}
                    </span>
                    <span className="text-xl font-medium">
                      {item?.Pylon_Stickers ? item?.Pylon_Stickers?.length : 0}
                    </span>
                  </div>
                </div>

                {item!.Pylon_Stickers!.length > 0 && (
                  <div className="relative box-border py-4">
                    <span className="mr-2 text-sm font-extralight text-gray-500">
                      {t('Pasted NFT')}
                    </span>
                    <div className="grid grid-cols-6 gap-2 pt-2">
                      {item?.Pylon_Stickers?.map((sticker) => {
                        const stickerData = sticker?.Stickers;
                        const url = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/${stickerData.user_id}/${stickerData.contract_address}_${stickerData.sticker_id}_194`;
                        return (
                          <FallbackImg
                            key={stickerData.id}
                            src={url}
                            alt="pylon"
                            className="h-[70px] w-[70px] object-cover"
                            fallbackSrc="/assets/loading.png"
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </CustomModal>
  );
};
