import anime from 'animejs';
import { isAfter } from 'date-fns';
import FileSaver from 'file-saver';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { Popover } from 'react-tiny-popover';

import { MintStatus, MintStatusType } from '../../../../types/mintStatus';
import { FallbackImg } from '../FallbackImg';

interface PylonCardProps {
  id: string;
  name: string;
  imageUrl: string;
  modelUrl: string;
  animationUrl: string;
  isEditable?: boolean;
  mintStatus?: MintStatusType;
  updatedAt: string;
}

export const PylonCard: React.FC<PylonCardProps> = ({
  id,
  name,
  imageUrl,
  mintStatus,
  modelUrl,
  animationUrl,
  updatedAt,
}) => {
  const { t } = useTranslation();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const shouldDisable =
    mintStatus === MintStatus.WAITING || mintStatus === MintStatus.MINTING;

  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.innerHTML = textRef.current.innerText.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );

      anime
        .timeline({ loop: true })
        .add({
          targets: `.animated${id} .letter`,
          translateY: ['1.1em', 0],
          translateZ: 0,
          delay: (_, i: number) => 70 * i,
        })
        .add({
          targets: `.animated${id}`,
          opacity: 0,
          duration: 1000,
          easing: 'easeOutExpo',
          delay: 1000,
        });
    }
  }, []);
  const studioLink = shouldDisable
    ? '#'
    : `/studio/mypylon/editor/${encodeURIComponent(id)} `;

  return (
    <a
      href={studioLink}
      className="flex w-full max-w-[330px] cursor-pointer flex-col px-5 shadow-sm hover:shadow-xl md:px-0 lg:w-[32%]"
    >
      <div
        className="relative flex h-[310px] w-full justify-center"
        style={{
          background: `url(/assets/loading.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          overflow: 'hidden',
          backgroundSize: '33px',
        }}
      >
        <span className="absolute z-10 mt-[6px] flex w-full justify-center p-1 text-center  text-[9px] font-semibold">
          {name}
        </span>
        <FallbackImg
          src={imageUrl}
          className="h-full w-full object-cover"
          alt={name}
        />
      </div>
      <div
        id={id}
        className="relative inline-flex items-center rounded bg-yellow-500 p-4 text-xl hover:bg-opacity-[0.7]"
      >
        <a
          href={studioLink}
          className="mx-auto text-base font-semibold uppercase"
        >
          {shouldDisable ? (
            <h1 className={`ml-6 ${`animated${id}`}`}>
              <span className="text-wrapper">
                <span ref={textRef}>{t('Minting')}</span>
              </span>
            </h1>
          ) : (
            t('open studio')
          )}
        </a>
        {isAfter(new Date(updatedAt), new Date('2023/1/19 00:00:00')) && (
          <Popover
            isOpen={isPopoverOpen}
            containerClassName="z-10 shadow-xl"
            positions={['bottom', 'left', 'right', 'top']}
            onClickOutside={() => setIsPopoverOpen(false)}
            padding={10}
            content={
              <>
                <div
                  onClick={() => {
                    fetch(modelUrl)
                      .then((res) => res.blob())
                      .then((blob) => {
                        const objectURL = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = objectURL;
                        a.download = `${name}.gltf`;
                        a.click();
                        a.remove();
                        setIsPopoverOpen(false);
                      });
                  }}
                  className="cursor-pointer bg-white px-4 py-2 text-sm hover:bg-gray-200"
                >
                  {t('Download 3D Object')}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsPopoverOpen(false);
                    FileSaver.saveAs(animationUrl, `${name}.gif`);
                  }}
                  className="cursor-pointer bg-white px-4 py-2 text-sm hover:bg-gray-200"
                >
                  {t('Download GIF')}
                </div>
              </>
            }
          >
            <div
              className="cursor-pointer px-1"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsPopoverOpen(!isPopoverOpen);
              }}
            >
              <FiMoreVertical />
            </div>
          </Popover>
        )}
      </div>
    </a>
  );
};
