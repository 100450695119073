import { useQuery } from 'react-query';

import { API } from '@/utils/api';

interface WaitTimeProps {
  start: Number;
  end: Number;
}

interface QueueStatusResponse {
  availableToPurchase: boolean;
  endTime: string;
  pylonNumber: number;
  userInQueue: boolean;
}

export const addToQueue = async () => {
  await API.post(`/queue/`, {}).then(() => {});
  return null;
};

export const deleteFromQueue = async () => {
  await API.delete(`/queue/`).then(() => {});
  return null;
};

export const useGetWaitTime = () => {
  return useQuery<WaitTimeProps, Error>(['waittime'], async () => {
    const response = await API.get<WaitTimeProps>(`/queue/waitTime`);
    return response.data;
  });
};

type QueueStatus = 'initial' | 'waiting' | 'available';
export const useQueueStatus = (loggedIn: boolean) => {
  const data = useQuery<QueueStatusResponse, Error>(
    ['queuestatus'],
    async () => {
      const response = await API.get<QueueStatusResponse>(`/queue`);
      return response.data;
    },
    { enabled: loggedIn }
  );
  let queueStatus: QueueStatus = 'initial';
  if (data.data?.userInQueue && !data.data?.availableToPurchase) {
    queueStatus = 'waiting';
  }
  if (data.data?.userInQueue && data.data?.availableToPurchase) {
    queueStatus = 'available';
  }
  if (!data.data?.userInQueue) {
    queueStatus = 'initial';
  }
  if (!loggedIn) {
    queueStatus = 'initial';
  }
  return { ...data, queueStatus };
};
