import { twMerge } from 'tailwind-merge';

interface MintStepProps {
  step: number;
  active?: boolean;
  heading: string;
  detail: string;
}

export const MintStep: React.FC<MintStepProps> = ({
  step,
  active = false,
  heading,
  detail,
}) => {
  return (
    <section
      className={twMerge(
        'box-content flex items-center border-2 border-black-500/100 bg-black-500',
        active
          ? 'bg-black-500 text-white border-l-transparent'
          : 'bg-white text-black-500',
        step > 2 ? 'opacity-75' : 'hover:bg-black-500 hover:text-white'
      )}
    >
      <h3
        className={twMerge(
          'leading-[3rem] text-[60px] lg:text-[85px] font-black',
          active ? 'text-yellow-500' : ''
        )}
      >
        {step}
      </h3>
      <div className="py-1 px-3 xl:px-0 xl:pr-1">
        <h3 className="whitespace-nowrap">{heading}</h3>
        <p className="text-sm font-light">{detail}</p>
      </div>
    </section>
  );
};
