interface Paginate {
  pages: number;
  activePage: number;
  setActivePage: (index: number) => void;
}

export const Pagination: React.FC<Paginate> = ({
  pages,
  activePage,
  setActivePage,
}) => {
  return (
    <>
      <section className="flex flex-row justify-center gap-2 py-14">
        {new Array(Number(pages)).fill(null).map((_, index) => (
          <div
            onClick={() => setActivePage(index)}
            key={index}
            className={`box-border cursor-pointer items-center justify-center rounded border-2 border-zinc-900 px-5 py-3 text-center  hover:bg-black-500 hover:text-white ${
              activePage === index && 'text-white bg-black-500'
            }`}
          >
            {index + 1}
          </div>
        ))}
      </section>
    </>
  );
};
