import MetaMaskOnboarding from '@metamask/onboarding';
import { ethers } from 'ethers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { useAuth } from '@/context/AuthContext';
import { useWeb3Accounts } from '@/context/Web3Accounts';
import { API } from '@/utils/api';
import * as ga from '@/utils/ga';
import { web3Util } from '@/utils/web3';

import { usePylonSync } from './studio/usePylonSync';

export const useMetaMask = () => {
  const [isWeb3Installed, setIsWeb3Installed] = useState(false);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  useEffect(() => {
    setIsWeb3Installed(typeof window?.ethereum !== 'undefined');
    setIsMetamaskInstalled(window?.ethereum?.isMetaMask);
  }, []);

  const { setAccounts } = useWeb3Accounts();
  const { setLoginCookie } = useAuth();
  const syncPylon = usePylonSync();

  const onboarding = useRef<MetaMaskOnboarding>();
  const startOnboarding = useCallback(() => {
    onboarding.current = new MetaMaskOnboarding();
    onboarding.current.startOnboarding();
  }, []);

  const updateStickers = useMutation(async (shouldWait?: boolean) => {
    // update sticker
    await API.post('/sticker', null, { params: { shouldWait } });
  });

  const connectMetamask = useMutation(
    async () => {
      setLoginCookie('');
      if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
        window?.open(process.env.NEXT_PUBLIC_DEEPLINK_URL);
        return { account: null, token: '' };
      }
      const { ethereum } = window;

      await web3Util.changeNetwork();
      await ethereum?.request({ method: 'eth_requestAccounts' });
      const ac = await web3Util.getEthAccount();
      if (ac.length === 0) {
        throw new Error('No accounts found');
      }
      const { data } = await API.get<{ nonce: number }>(`/${ac[0]}/nonce`);
      const providers = await new ethers.providers.Web3Provider(ethereum);
      const signers = providers.getSigner();
      const message = await signers.signMessage(`Nonce: ${data?.nonce}`);
      const { data: token } = await API.post<{ token: string }>('/login', {
        walletAddress: ac[0],
        signature: message,
      });
      setLoginCookie(token.token);
      await updateStickers.mutateAsync(undefined);
      syncPylon.mutate();
      return { account: ac, ...token };
    },
    {
      onSuccess: async ({ account }) => {
        setAccounts(account);
        if (account) {
          ga.sendUserIdToGA(account[0]);
        }
      },
      onError: () => {},
    }
  );

  return {
    isWeb3Installed,
    isMetamaskInstalled,
    connectMetamask,
    updateStickers,
    startOnboarding,
  };
};
