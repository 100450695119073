import { FaSpinner } from 'react-icons/fa';

interface StickerProps {
  title: string;
  subTitle: string;
  tag?: string;
  onRefresh?: () => void;
  refreshLoading?: boolean;
}

export const StickerPacksHeading: React.FC<StickerProps> = ({
  title,
  subTitle,
  tag,
  onRefresh,
  refreshLoading,
}) => (
  <section className="mb-[15px] px-5 md:mb-[80px] md:px-0">
    <div className="flex items-center pt-10 font-sans text-[14px] md:text-2xl">
      <div>{title}</div>
      {onRefresh && (
        <button
          disabled={refreshLoading}
          onClick={onRefresh}
          className="ml-4 inline-flex h-[28px] w-[92px] items-center justify-center rounded bg-black-500 px-3 py-1 text-xs font-semibold text-white hover:bg-opacity-[0.7]"
        >
          {refreshLoading ? <FaSpinner className="animate-spin" /> : 'Refresh'}
        </button>
      )}
    </div>
    <div className="pt-1 font-sans text-[14px] font-light text-[#797979] md:text-[15px]">
      {subTitle}
    </div>
    {tag && (
      <div className="mt-5 w-36 rounded-2xl bg-black-600 px-3 py-1 text-center text-xs font-light uppercase text-white">
        {tag}
      </div>
    )}
  </section>
);
