import { Pack } from '@prisma/client';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { IAllowedResponse } from '@/hooks/packs/usePacks';

import { AskDiscordBtn } from '../../atoms/AskDiscordBth';
import { PackGetStatusCard } from '../../atoms/PackGetStatusCard';

interface Props {
  pack: Pack;
  data: IAllowedResponse;
}

export const HowToGetPacks = ({ data, pack }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="pt-[50px]">
      <div className="flex items-center gap-4  text-lg md:gap-12">
        <h3 className="text-lg font-bold">{t('How to Get')}</h3>
        <AskDiscordBtn href="https://discord.com/invite/mTMEwUNYEe" />
      </div>
      <div className="mt-6 max-w-[700px] text-sm">
        {pack?.condition_message}
      </div>
      <div className="mt-10 flex w-full flex-col gap-4 md:flex-row">
        {pack?.condition_wl && (
          <PackGetStatusCard
            name={t('WHITE LIST')}
            buttonText={t('GET a WHITE LIST')}
            footer={t(
              'If you are not in our WL, please feel free to ask us to add you.'
            )}
            fulfilled={data.inWhiteList}
            buttonHref={
              'https://docs.google.com/forms/d/e/1FAIpQLSfoA0WjGsixYcTCSTWMDQd6Rwor5RyVUfCohpkfL7ZVK-ubHA/viewform'
            }
            hrefTarget="_blank"
          />
        )}
        {pack?.condition_pylon && (
          <PackGetStatusCard
            name="META PYLON"
            buttonText={t('GET a METAPYLON')}
            footer={t('If you don’t have a META PYLON, please get one now!')}
            fulfilled={data.hasPylon}
            buttonHref="/mint"
          />
        )}
        {pack?.condition_contracts && (
          <PackGetStatusCard
            name="NFT"
            buttonText={t('GET an NFT')}
            buttonHref={pack?.opensea_url}
            hrefTarget="_blank"
            footer={
              <>
                <div>{t('If you already have the NFT:')}</div>

                <Link href="/studio/mypylon" passHref>
                  <a className="underline hover:opacity-80">
                    {t('Refresh your collection')}
                  </a>
                </Link>
              </>
            }
            fulfilled={data?.hasNFT}
          />
        )}
      </div>
    </div>
  );
};
