import { StickerImage } from '@wooltrakey/meta-pylon-3d';
import { useState } from 'react';
import { BiHide } from 'react-icons/bi';
import { FaSpinner } from 'react-icons/fa';
import { FiEye } from 'react-icons/fi';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API } from '@/utils/api';

import { CustomIcon, FallbackImg, Tooltip } from '../../atoms';

interface ISticker extends StickerImage {
  id: string;
  contract_address: string;
  network?: string;
}
interface Props {
  sticker: ISticker;
  type?: 'hide' | 'unhide';
}

const appEnv = process.env.NEXT_PUBLIC_APP_ENV;

const generateOpenSeaUrl = (mSticker: ISticker): string => {
  const token = Number(mSticker.sticker_id);
  let openseaUrl = '';
  if (appEnv === 'production') {
    const network = mSticker.network === 'polygon' ? 'matic' : 'ethereum';
    openseaUrl = `https://opensea.io/assets/${network}/${mSticker.contract_address}/${token}`;
  } else {
    const network = mSticker.network === 'polygon' ? 'mumbai' : 'goerli';
    openseaUrl = `https://testnets.opensea.io/assets/${network}/${mSticker.contract_address}/${token}`;
  }
  return openseaUrl;
};

const constructStatus = (status: string, error: string) => {
  switch (status) {
    case 'initial':
      return { hasError: false, status: 'Downloading' };
    case 'download-completed':
      return { hasError: false, status: 'Compressing' };
    case 'download-failed': {
      let additionalMessage = '';
      if (error.includes('Extension not allowed')) {
        additionalMessage =
          'The extension of the image is not supported. The supported sticker extensions are png, jpg, jpeg, gif, webp and svg.';
      }
      if (error.includes('Only HTTP(S) protocols are supported')) {
        additionalMessage = 'Sticker metadata contains invalid protocol.';
      }
      if (error.includes('ENOTFOUND')) {
        additionalMessage =
          'The NFT image was not found in the provided url and the request to fetch data failed. ';
      }
      if (error.includes("Extension couldn't be determined.")) {
        additionalMessage =
          "The extension of the image couldn't be determined. ";
      }
      return {
        hasError: true,
        status: `Failed to download the image. ${additionalMessage}`,
      };
    }
    case 'compression-error':
      return { hasError: true, status: 'Failed to compress the image' };
    default:
      return {
        hasError: true,
        status: 'Failed to load the image',
      };
  }
};

export const StickerCard = ({ sticker, type = 'hide' }: Props) => {
  const url = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/${sticker.user_id}/${sticker.contract_address}_${sticker.sticker_id}_194`;
  const filePath = `${sticker.user_id}/${sticker.contract_address}_${sticker.sticker_id}`;

  const queryClient = useQueryClient();
  const [isHideVisible, setHideVisible] = useState(false);
  const [shouldRunLambda, setshouldRunLambda] = useState(false);

  const unhideMutation = useMutation(async (nftId: string) => {
    // update sticker
    await API.post('/sticker/visibility', {
      id: nftId,
      hidden: type === 'hide',
    });
    queryClient.invalidateQueries(['stickers']);
  });

  const { data } = useQuery(
    ['stickerLambda', filePath],
    async () => {
      const response = await API.post(`/sticker/status`, { filePath });
      return response?.data;
    },
    {
      enabled: shouldRunLambda,
    }
  );

  const iconMap = {
    hide: <BiHide />,
    unhide: <FiEye />,
  };

  return (
    <div
      className="relative w-full rounded"
      key={sticker?.id}
      onMouseEnter={() => setHideVisible(true)}
      onMouseLeave={() => setHideVisible(false)}
    >
      <FallbackImg
        src={url}
        alt="sticker"
        className="relative h-[246px] w-full rounded-lg object-cover"
        fallbackSrc="/assets/loading.png"
        status={constructStatus(data?.status, data?.error)}
        shouldLoad={() => setshouldRunLambda(true)}
      />

      <div className="absolute top-0 left-0 w-full">
        {isHideVisible && (
          <div className="absolute top-2 right-2">
            <div className="flex items-center space-x-3">
              <button
                disabled={unhideMutation.isLoading}
                onClick={() => unhideMutation.mutate(sticker.id)}
                className="rounded-full bg-white p-2 opacity-80 shadow hover:opacity-100"
              >
                <Tooltip
                  message={`${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  } NFT`}
                  className="whitespace-nowrap"
                >
                  {unhideMutation.isLoading ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    iconMap[type]
                  )}
                </Tooltip>
              </button>
              {sticker?.network && (
                <a
                  href={generateOpenSeaUrl(sticker)}
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full bg-white p-2 opacity-80 shadow hover:opacity-100"
                >
                  <Tooltip
                    message="View in OpenSea"
                    className="whitespace-nowrap"
                  >
                    <CustomIcon type="opensea" className="h-4 w-4" />
                  </Tooltip>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
