import { IconProps } from './IconProps';

export const BoatIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <svg viewBox="0 0 100 100" className={className}>
      <path
        fill="#000000"
        d="M100,50c0,27.6-22.4,50-50,50S0,77.6,0,50S22.4,0,50,0C77.6,0,100,22.4,100,50z"
      />
      <path
        fill="#FFFFFF"
        d="M24.7,51.7l0.2-0.3l13-20.3c0.2-0.3,0.6-0.3,0.8,0.1c2.2,4.9,4,10.9,3.2,14.7c-0.4,1.6-1.4,3.7-2.6,5.6
   c-0.1,0.3-0.3,0.6-0.5,0.8c-0.1,0.1-0.2,0.2-0.4,0.2H25C24.7,52.4,24.5,52,24.7,51.7z"
      />
      <path
        fill="#FFFFFF"
        d="M82.6,55.5v3.2c0,0.2-0.1,0.3-0.3,0.4c-1,0.4-4.5,2-5.9,4c-3.7,5.1-6.5,12.4-12.7,12.4H37.7
   C28.5,75.5,21,68,21,58.7v-0.3c0-0.2,0.2-0.4,0.4-0.4H36c0.3,0,0.5,0.3,0.5,0.5c-0.1,0.9,0.1,1.9,0.5,2.8c0.9,1.8,2.7,2.8,4.6,2.8
   h7.2v-5.6h-7.1c-0.4,0-0.6-0.4-0.4-0.7c0.1-0.1,0.2-0.2,0.3-0.4c0.7-1,1.6-2.4,2.6-4.1c0.7-1.1,1.3-2.4,1.8-3.6
   c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.4,0.3-0.8,0.4-1.1c0.1-0.3,0.2-0.6,0.3-0.9c0.2-1,0.3-2.1,0.3-3.3c0-0.4,0-0.9-0.1-1.4
   c0-0.5-0.1-1-0.1-1.5c0-0.4-0.1-0.9-0.2-1.3c-0.1-0.7-0.2-1.3-0.4-2l-0.1-0.2c-0.1-0.4-0.2-0.9-0.4-1.3c-0.4-1.4-0.9-2.8-1.4-4
   c-0.2-0.5-0.4-1-0.6-1.5c-0.3-0.7-0.6-1.4-0.9-2c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.2-0.4-0.3-0.6
   l-0.9-1.6c-0.1-0.2,0.1-0.5,0.3-0.4l5.5,1.5h0c0,0,0,0,0,0l0.7,0.2l0.8,0.2l0.3,0.1v-3.3c0-1.6,1.3-2.9,2.8-2.9c0.8,0,1.5,0.3,2,0.8
   c0.5,0.5,0.8,1.2,0.8,2v4.8l0.6,0.2c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.3,0.6,0.5c0.2,0.2,0.4,0.4,0.7,0.6c0.5,0.4,1.2,1,1.9,1.6
   c0.2,0.2,0.4,0.3,0.5,0.5c0.9,0.8,1.9,1.8,2.9,2.9c0.3,0.3,0.5,0.6,0.8,0.9c0.3,0.3,0.5,0.7,0.8,1c0.3,0.4,0.7,0.9,1,1.3
   c0.1,0.2,0.3,0.4,0.4,0.7c0.4,0.6,0.7,1.2,1.1,1.8c0.1,0.3,0.3,0.6,0.4,0.9c0.4,0.8,0.7,1.6,0.8,2.5c0.1,0.2,0.1,0.4,0.1,0.5v0
   c0.1,0.2,0.1,0.5,0.1,0.8c0.1,0.9,0,1.7-0.1,2.6c-0.1,0.4-0.2,0.7-0.3,1.1c-0.1,0.3-0.2,0.7-0.4,1.1c-0.3,0.7-0.7,1.4-1.1,2.1
   c-0.1,0.2-0.3,0.5-0.5,0.8c-0.2,0.3-0.4,0.5-0.5,0.8c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.4,0.6-0.7,0.9c-0.3,0.4-0.7,0.8-1,1.2
   c-0.2,0.2-0.4,0.5-0.7,0.7c-0.2,0.2-0.4,0.5-0.7,0.7c-0.3,0.3-0.6,0.6-0.9,0.8l-0.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.1h-4.4v5.6h5.5
   c1.2,0,2.4-0.4,3.3-1.2c0.3-0.3,1.7-1.5,3.4-3.3c0.1-0.1,0.1-0.1,0.2-0.1L82.1,55C82.4,54.9,82.6,55.2,82.6,55.5z"
      />
    </svg>
  );
};
