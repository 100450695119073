import React, { MouseEventHandler } from 'react';
import { VscClose } from 'react-icons/vsc';

interface CustomModalProps {
  children: JSX.Element;
  visible: boolean;
  bgColor?: string;
  onClose?: MouseEventHandler<any>;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  children,
  visible,
  bgColor,
  onClose,
}) => {
  return (
    <>
      {visible ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto w-auto px-[20px]">
              <div
                className={`flex w-full max-w-5xl flex-col border-0 ${
                  bgColor ? `bg-${bgColor}` : 'bg-[rgba(0,0,0,0.8)]'
                } shadow-lg outline-none focus:outline-none`}
              >
                <VscClose
                  className="mt-5 mr-5 h-8 w-8 cursor-pointer self-end"
                  color={bgColor === 'white' ? 'black' : 'white'}
                  onClick={onClose}
                />
                {children}
              </div>
            </div>
          </div>
          <div
            className="fixed inset-0 z-40 bg-black-600 opacity-25"
            onClick={onClose}
          ></div>
        </>
      ) : null}
    </>
  );
};
