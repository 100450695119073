import 'react-accessible-accordion/dist/fancy-example.css';

import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Fragment, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

type ItemType = {
  title: string;
  description: string;
};
type Props = {
  item: ItemType;
};

const FAQDropdown: React.FC<Props> = ({ item }) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <AccordionItemHeading
        className="rounded-lg"
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <AccordionItemButton className="relative z-40 flex justify-between overflow-hidden bg-[#EAEAEA] object-contain px-4 py-8 font-bold md:p-8">
          {t(item.title)}
          <span className="hidden sm:block">
            <Image
              src={`${
                accordionOpen
                  ? '/assets/landing/chevron_up.svg'
                  : '/assets/landing/chevron_down.svg'
              }`}
              alt=""
              height={20}
              width={20}
            />
          </span>
          <span className="absolute left-10 -z-10 mt-[-90px] mr-[50px] font-sans text-9xl font-bold text-[#FFF600] sm:left-0 md:left-5">
            Q
          </span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <div className="relative z-30 bg-white py-2 px-0 md:px-8">
        <AccordionItemPanel className="min-h-[90px]">
          <span className="absolute right-10 bottom-0 h-[90px] font-sans text-9xl font-bold leading-[5rem] text-[#FFF600] sm:right-0 md:right-5">
            A
          </span>
          <span className="relative whitespace-pre-line">
            {t(item.description)}
          </span>
        </AccordionItemPanel>
      </div>
    </>
  );
};

export const AccordianFAQs: React.FC = () => {
  const FAQData = [
    {
      title: 'What is META PYLON?',
      description:
        'Created by NFT studio WoOLTRAKEY, META PYLON is a 5555 piece on-chain interactive NFT product. Owners have access to 3D model data that can be placed on the Metaverse.',
    },
    {
      title: 'What is META PYLON STUDIO?',
      description:
        'META PYLON STUDIO is a special function that allows you to freely paste NFTs in your wallet. You can paste your NFTs on Pylon with any angle, large or small.',
    },
    {
      title: 'What experiences does META PYLON offer?',
      description:
        'The stickers will be generated from your NFTs, your original NFTs will not be lost, and you can paste them on Pylons. All pylons are managed on the blockchain and only one NFT can exist on the META PYLON at the same time.',
    },
    {
      title: 'Will it be sold to the public?',
      description:
        'All META PYLON sales will be made to community whitelistings on a rolling basis. We will be using OpenSea platform once we launch all the NFTs.',
    },
    {
      title: 'When will META PYLON be minted?',
      description:
        'We plan to start in mid Aug 2022. Please wait for more information updates on Twitter and Discord communities.',
    },
    {
      title: 'What is the vision for META PYLON?',
      description:
        'Generate object NFTs not limited to Pylon, and generate NFTs with stickers on them in the real world as well. The world of META PYLON will be completed by existing back and forth between the Metaverse and the real world.',
    },
    {
      title: 'What is the significance of META PYLON being-on-chain?',
      description:
        "META PYLON is a unique three-dimensional NFT art form in itself, and it is a metaphor for the creativity of NFT, the pleasure of collecting it, and one's own sensibility, which can be varied by its owner and community. It functions as an object. We aim to create a DAO that functions as an aggregate that evolves not only with the visuals of each META PYLON, but also with all its extensibility, along with the art graphs attached to it.",
    },
    {
      title: 'What is META STICKER? How do I get it?',
      description:
        'Owners of META PYLON can frequently receive original creator collaboration stickers. We provide the experience of opening NFT sticker packs. Of course you can also purchase it. Please wait for air drop information on discord or twitter.',
    },
  ];

  return (
    <>
      <Accordion className="w-full" allowMultipleExpanded allowZeroExpanded>
        {FAQData.map((item) => (
          <Fragment key={item.title}>
            <div className="relative h-[20px] bg-white"></div>
            <AccordionItem className="rounded-lg" key={item.title}>
              <FAQDropdown item={item} />
            </AccordionItem>
          </Fragment>
        ))}
      </Accordion>
    </>
  );
};
