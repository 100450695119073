import React, { useRef, useState } from 'react';

export const Draggable = ({ children, className }: any) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [dragging, toggleDragging] = useState(false);
  const [pos, setPos] = useState({ top: 0, left: 0, x: 0, y: 0 });
  const draggableRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onMouseDown = (e: any) => {
    setIsScrolling(true);
    setPos({
      // The current scroll
      left: draggableRef.current.scrollLeft,
      top: draggableRef.current.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    });
  };

  const onMouseMove = (e: any) => {
    if (isScrolling) {
      if (e.clientX !== pos.x) {
        toggleDragging(true);
      }

      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      draggableRef.current.scrollTop = pos.top - dy;
      draggableRef.current.scrollLeft = pos.left - dx;
    }
  };

  const onMouseUp = () => {
    setIsScrolling(false);
  };

  const handleCapture = (e: any) => {
    if (dragging) {
      e.stopPropagation();
      toggleDragging(false);
    }
  };

  return (
    <>
      <div
        className={`flex flex-row select-none overflow-x-auto pb-[60px] ${className}`}
        ref={draggableRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseLeave={() => setIsScrolling(false)}
        onClickCapture={handleCapture}
      >
        {children}
      </div>
    </>
  );
};
