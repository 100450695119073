import { StickerImage } from '@wooltrakey/meta-pylon-3d';
import { useInfiniteQuery, useQuery } from 'react-query';

import { API } from '@/utils/api';

export interface Sticker {
  hidden: number;
  pages: string;
  stickers: Array<StickerImage & { id: string; contract_address: string }>;
}

export const useStickers = (skip?: number, take?: number, type?: string) => {
  return useQuery<Sticker, Error>(
    ['stickers', skip, take],
    async () => {
      const url =
        Number(skip) >= 0 && Number(take) >= 0
          ? `/sticker?skip=${skip}&take=${take}`
          : '/sticker';
      const response = await API.get<Sticker>(url, { params: { type } });
      return response?.data;
    },
    { keepPreviousData: true }
  );
};

export const useInfiniteStickers = (size: number = 10) => {
  return useInfiniteQuery(
    ['infiniteStickers'],
    async ({ pageParam = 0 }) => {
      const url = `/sticker?skip=${pageParam * size}&take=${size}`;
      const { data } = await API.get<Sticker>(url);
      data.stickers = data.stickers.map((s) => ({
        ...s,
      }));
      return data;
    },
    {
      getNextPageParam: (last, pages) => {
        if (+last.pages === pages.length) return undefined;
        return pages.length;
      },
    }
  );
};
