import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { TbLanguage } from 'react-icons/tb';

import { CustomDropdown } from '../CustomDropdown';

const options = ['English', '日本語'];

export const LanguageSwitcher = ({ className }: { className?: string }) => {
  const router = useRouter();
  const [currentLocale, setCurrentLocale] = useState('English');

  const handleLocaleChange = useCallback((locale: string) => {
    setCurrentLocale(locale);
    const mLocale = locale === 'English' ? 'en' : 'ja';
    localStorage.setItem('lang', mLocale);
    const path = router.asPath;
    router.push(path, path, { locale: mLocale });
  }, []);

  useEffect(() => {
    if (router.isReady) {
      const lang = localStorage.getItem('lang') ?? router.locale;
      if (lang === 'ja') {
        setCurrentLocale('日本語');
        const path = router.asPath;
        router.push(path, path, { locale: lang });
      } else {
        setCurrentLocale('English');
      }
    }
  }, []);

  return (
    <CustomDropdown
      buttonStyle={className}
      options={options}
      icon={<TbLanguage className="mr-2 text-gray-700" />}
      name={currentLocale}
      onSelect={(val) => handleLocaleChange(val)}
    />
  );
};
