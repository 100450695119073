/* eslint-disable @next/next/no-html-link-for-pages */
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useLoggedIn } from '@/context/useLoggedIn';
import { useMetaMask } from '@/hooks';

import { MintStep } from '../../atoms';

export const MintSteps: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { connectMetamask } = useMetaMask();
  const { loggedIn } = useLoggedIn();
  const router = useRouter();

  const connectAndEnterStudio = async () => {
    if (!loggedIn) {
      await connectMetamask.mutateAsync();
      router.push('/studio/mypylon');
    } else {
      router.push('/studio/mypylon');
    }
  };

  return (
    <section className="mt-16 grid gap-y-8 md:grid-cols-2 md:gap-x-8 xl:grid-cols-4 xl:gap-x-4">
      <MintStep
        step={1}
        active
        heading={t('Get Your PYLON')}
        detail={t('Whitelisted or get a pylon in the secondary market.')}
      />
      <a onClick={connectAndEnterStudio}>
        <MintStep
          step={2}
          heading={t('Enter STUDIO')}
          detail={t('Use our STUDIO web app and customize PYLON.')}
        />
      </a>
      <MintStep
        step={3}
        heading={t('Showcase your sense')}
        detail={t('Put your PYLON on Meta verse world and SNS icon.')}
      />
      <MintStep
        step={4}
        heading={t('Join M.P. World')}
        detail={t('Wait to get your physical pylons and get our stickers.')}
      />
    </section>
  );
};
