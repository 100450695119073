import { useMutation, useQueryClient } from 'react-query';

import { API } from '@/utils/api';

export const usePylonSync = () => {
  const queryClient = useQueryClient();
  return useMutation(() => API.get('mypylon/sync'), {
    onSuccess: () => {
      queryClient.invalidateQueries('mypylons');
    },
  });
};
