import { FiHeart } from 'react-icons/fi';

import { formatNumber } from '@/utils/formatNumber';

import { FallbackImg } from '../FallbackImg';

interface GalleryCardProps {
  image: string;
  title: string;
  subTitle: string;
  price: number;
  expiry: string;
  likes: string;
  animationUrl: string;
  onClick: (data: any) => void;
}

export const GalleryCard: React.FC<GalleryCardProps> = ({
  title,
  image,
  likes,
  onClick,
  animationUrl,
}: GalleryCardProps) => {
  return (
    <>
      <section
        onClick={() => onClick('data')}
        className="flex w-full cursor-pointer flex-col rounded-lg border-2 border-gray-200 hover:opacity-[0.8] hover:shadow"
      >
        <FallbackImg
          src={animationUrl}
          fallbackSrc={image}
          alt="gallery item"
          className="h-[260px] w-full rounded-t-lg object-cover"
        />

        <div className="flex justify-between p-3">
          <div className="flex flex-col items-start text-sm">
            <span className="font-bold">{title}</span>
          </div>
        </div>

        <div className="flex items-center justify-end border-t-2 p-3 text-sm">
          <FiHeart onClick={() => {}} className="mr-1 text-xl text-gray-500" />
          <span className="text-gray-500">
            {formatNumber(Number(likes), 1) || 0}
          </span>
        </div>
      </section>
    </>
  );
};
