import { useQuery } from 'react-query';

import { Pylon } from '@/types/pylon';
import { API } from '@/utils/api';

interface GalleryResponse {
  pages: string;
  pylons: Array<Pylon>;
}

export const useGallery = (skip?: number, take?: number) => {
  return useQuery<GalleryResponse, Error>(
    ['gallery', skip, take],
    async () => {
      const url =
        Number(skip) >= 0 && Number(take) >= 0
          ? `/pylons?skip=${skip}&take=${take}`
          : '/pylons';
      const response = await API.get<GalleryResponse>(url);
      return {
        pages: response?.data?.pages,
        pylons: response?.data?.pylons?.map((m) => ({
          ...m,
          likes: m.Likes?.likes ?? '0',
        })),
      };
    },
    { keepPreviousData: true }
  );
};

export const useIndividualGallery = (id?: string) => {
  return useQuery<Pylon, Error>(['pylons', id], async (ctx) => {
    const itemId = ctx.queryKey[1] ?? null;
    const response = await API.get<Pylon>(`/pylons/${itemId}`);
    response.data.likes = response.data?.Likes?.likes ?? '0';
    response.data.views = response.data?.Views?.views ?? '0';
    return response.data;
  });
};

interface ViewsProps {
  views: string;
}

export const incrementViews = async (id: string) => {
  let data: Array<ViewsProps> = [];

  await API.get(`/views/${id}`).then((response) => {
    data = response.data?.views;
  });
  return data;
};

export const incrementLikes = async (id: string) => {
  let data = 0;

  await API.post(`/likes/${id}`, {}).then((response) => {
    data = response.data?.likes;
  });
  return data;
};
