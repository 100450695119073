import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useAuth } from '@/context/AuthContext';
import { useWeb3Accounts } from '@/context/Web3Accounts';

import { CustomDropdown, LanguageSwitcher } from '../../atoms';
import { SocialIcons } from '../../molecules';

interface Props {
  className?: string;
  hideSocial?: boolean;
}

export const StudioNavBar: React.FC<Props> = ({ className, hideSocial }) => {
  const { accounts } = useWeb3Accounts();
  const { logoutUser } = useAuth();
  const { t } = useTranslation();

  const account = accounts[0];
  const displayAccount = `${account?.substring(0, 7)}...${account?.substring(
    account.length - 7,
    account.length
  )}`;

  const handleDropdownSelect = (option: string) => {
    if (option === t('Disconnect')) {
      logoutUser();
    }
  };

  return (
    <>
      <nav
        className={twMerge(
          'h-[80px] p-4 px-5 md:px-0 rounded flex flex-wrap items-center justify-center gap-2 sm:gap-1  text-center sm:justify-between',
          className
        )}
      >
        <div className="flex flex-row gap-6">
          <Link href="/studio">
            <a>
              <Image
                src="/assets/landing/meta-pylon-logo.png"
                alt="metapylon logo"
                width="230px"
                height="18px"
                objectFit="contain"
              />
            </a>
          </Link>

          {!hideSocial && (
            <SocialIcons
              className="mt-0 pl-5"
              boatLink="https://opensea.io/collection/metapylonnft"
              discordLink="https://discord.com/invite/mTMEwUNYEe"
              twitterLink="https://twitter.com/MetaPylon"
            />
          )}
        </div>
        <div className="flex items-center space-x-5">
          <LanguageSwitcher />
          {!!account && (
            <div>
              <CustomDropdown
                onSelect={handleDropdownSelect}
                options={[t('Disconnect')]}
                image="/assets/favicon.png"
                wallet={displayAccount}
              />
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
