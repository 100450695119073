interface CustomErrorProps {
  errorMessage: string;
}

export const CustomError: React.FC<CustomErrorProps> = ({ errorMessage }) => {
  return (
    <div className="relative mt-3 w-full" role="alert">
      <span className="block sm:inline">{errorMessage}</span>
    </div>
  );
};

CustomError.defaultProps = {
  errorMessage: 'Error! Please try again later.',
};
