import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';

interface Props {
  name: string;
  buttonText: string;
  buttonHref?: string | null;
  hrefTarget?: string;
  footer: string | ReactNode;
  fulfilled: boolean | undefined;
}

export const PackGetStatusCard = ({
  name,
  fulfilled,
  buttonHref,
  hrefTarget,
  buttonText,
  footer,
}: Props) => {
  return (
    <div className="flex flex-col  sm:w-full md:w-1/3">
      <div className="flex h-36 w-full  flex-col items-center justify-center rounded-md border-[1px] border-[#CECECE] bg-[#F4F4F4]">
        <Image
          src={`/assets/check-${fulfilled ? 'green' : 'white'}.png`}
          width="32px"
          height="32px"
          alt="check"
        />
        <span
          className={`${
            fulfilled ? 'text-[#10D010]' : 'text-[#cfcfcf]'
          } mt-2 font-bold`}
        >
          {name}
        </span>
      </div>
      {!fulfilled && (
        <>
          {buttonHref && (
            <div className="px-2 py-5">
              <Link href={buttonHref} passHref>
                <a
                  target={hrefTarget}
                  className="box-border block w-full cursor-pointer rounded-full bg-black-500 py-2 px-6 text-center text-sm text-white hover:opacity-80"
                >
                  {buttonText}
                </a>
              </Link>
            </div>
          )}
          <div className="px-2 text-center text-sm">{footer}</div>
        </>
      )}
    </div>
  );
};
