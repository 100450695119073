import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { CustomIcon } from '../../atoms';

interface SocialIconsProps {
  discordLink: string;
  boatLink: string;
  twitterLink: string;
  className?: string;
}

export const SocialIcons: React.FC<SocialIconsProps> = ({
  discordLink,
  boatLink,
  twitterLink,
  className,
}) => {
  return (
    <>
      <section
        className={twMerge('mt-8 flex flex-row gap-2 items-center', className)}
      >
        <Link href={discordLink}>
          <a target="_blank" rel="noopener noreferrer">
            <CustomIcon type="discord" />
          </a>
        </Link>
        <Link href={boatLink}>
          <a target="_blank" rel="noopener noreferrer">
            <CustomIcon type="opensea" className="h-4 w-4" />
          </a>
        </Link>
        <Link href={twitterLink}>
          <a target="_blank" rel="noopener noreferrer">
            <CustomIcon type="twitter" />
          </a>
        </Link>
      </section>
    </>
  );
};
